
import { Vue, Component } from "vue-property-decorator";

@Component
export default class MyRequestsTable extends Vue {
  private columns = [
    {
      name: "ticket",
      required: true,
      label: "Ticket",
      field: "id",
      align: "center",
      sortable: true
    },
    {
      name: "date",
      required: true,
      label: "Fecha y Hora",
      field: "date",
      align: "center",
      sortable: true
    },
    {
      name: "service",
      align: "center",
      label: "Servicio",
      field: "service",
      sortable: true
    },
    {
      name: "status",
      label: "Estado",
      field: "status",
      align: "center",
      sortable: true
    },
    {
      name: "details",
      label: "Detalle",
      align: "center",
      sortable: false
    }
  ];

  private tickets = [];
  finish_loading = false;

  private mounted() {
    Vue.nextTick(() =>
      this.$axios
        .get("tickets/listar", {
          headers: { Authorization: this.$store.state.user.auth_token }
        })
        .then(response => {
          this.tickets = response.data.response as any;
          this.finish_loading = true;
        })
        .catch(error => {})
    );
  }

  private getPaginationLabel(
    firstRowIndex: number,
    endRowIndex: number,
    totalRowsNumber: number
  ) {
    return firstRowIndex + " - " + endRowIndex + " de " + totalRowsNumber;
  }

  private getTextColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = "text-yellow";
        break;
      case 2:
        color = "text-orange";
        break;
      case 3:
        color = "text-purple";
        break;
      case 4:
        color = "text-cyan";
        break;
      case 5:
        color = "text-blue";
        break;
      case 8:
        color = "text-lightgreen";
        break;
      case 6:
        color = "text-green";
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getBtnColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = "yellow";
        break;
      case 2:
        color = "orange";
        break;
      case 3:
        color = "purple";
        break;
      case 4:
        color = "cyan";
        break;
      case 5:
        color = "blue";
        break;
      case 8:
        color = "lightgreen";
        break;
      case 6:
        color = "green";
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private humanDate(date: any) {
    let date_object = new Date(date);
    return date_object.toLocaleString("es-CL", { timeZone: "UTC" });
  }

  private getStatusName(state: number) {
    let status_name = "Desconocido";
    switch (state) {
      case 1:
        status_name = "Gestión";
        break;
      case 2:
        status_name = "Revisión";
        break;
      case 3:
        status_name = "Derivado";
        break;
      case 4:
        status_name = "Para VB Jefe";
        break;
      case 8:
        status_name = "Con VB Director";
        break;
      case 5:
        status_name = "VB Director";
        break;
      case 6:
        status_name = "Cerrado";
        break;
      default:
        status_name = "Desconocido";
        break;
    }
    return status_name;
  }
}

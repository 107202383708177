var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.finish_loading)?_c('q-table',{staticClass:"full-width q-mb-md",attrs:{"grid":_vm.$q.screen.lt.sm,"table-header-class":'table-header',"data":_vm.tickets,"columns":_vm.columns,"rows-per-page-label":'Resultados por página',"pagination-label":_vm.getPaginationLabel,"row-key":"name","color":"amber"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',[_c('q-td',{key:"ticket",attrs:{"props":props}},[_vm._v(_vm._s(props.row.id))]),_c('q-td',{key:"date",attrs:{"props":props}},[_vm._v(_vm._s(_vm.humanDate(props.row.fecha)))]),_c('q-td',{key:"service",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.servicio_nombre)+" ")]),_c('q-td',{key:"status",class:_vm.getTextColor(props.row.estado_ticket),attrs:{"props":props}},[_vm._v(_vm._s(_vm.getStatusName(props.row.estado_ticket)))]),_c('q-td',{staticClass:"text-center"},[_c('q-btn',{staticClass:"q-mb-sm",attrs:{"rounded":"","color":_vm.getBtnColor(props.row.estado_ticket),"icon":"o_inbox"},on:{"click":function($event){return _vm.$router.push({
              name: 'requestdetails',
              params: { id_ticket: props.row.id, quick_search: true }
            })}}})],1)],1)]}},{key:"item",fn:function(props){return [_c('q-card',{staticClass:"flex full-width q-my-md",attrs:{"props":props}},[_c('q-card-section',{staticClass:"full-width"},[_c('div',{staticClass:"item-name"},[_vm._v("Ticket:")]),_c('div',{staticClass:"item-description"},[_vm._v(_vm._s(props.row.id))])]),_c('q-card-section',{staticClass:"full-width"},[_c('div',{staticClass:"item-name"},[_vm._v("Fecha:")]),_c('div',{staticClass:"item-description"},[_vm._v(_vm._s(_vm.humanDate(props.row.fecha)))])]),_c('q-card-section',{staticClass:"full-width"},[_c('div',{staticClass:"item-name"},[_vm._v("Estado:")]),_c('div',{staticClass:"item-description",class:_vm.getTextColor(props.row.estado_ticket)},[_vm._v(" "+_vm._s(_vm.getStatusName(props.row.estado_ticket))+" ")])]),_c('q-card-section',{staticClass:"full-width"},[_c('div',{staticClass:"item-name"},[_vm._v("Detalle:")]),_c('q-btn',{staticClass:"q-mb-sm full-width",attrs:{"rounded":"","color":_vm.getBtnColor(props.row.estado_ticket),"icon":"o_inbox"},on:{"click":function($event){return _vm.$router.push({
              name: 'requestdetails',
              params: { id_ticket: props.row.id, quick_search: true }
            })}}})],1)],1)]}}],null,false,882927843)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }